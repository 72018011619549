import { Suspense, lazy } from 'react';
import type { ClassKey } from 'keycloakify/login';
import type { KcContext } from './KcContext';
import { useI18n } from './i18n';
import { tss } from 'tss-react/mui';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './main.css';
import DefaultPage from 'keycloakify/login/DefaultPage';
import Template from './Template';
import { themeStyles } from 'styles';

const UserProfileFormFields = lazy(() => import('keycloakify/login/UserProfileFormFields'));
const Login = lazy(() => import('./pages/Login'));
const LoginResetPassword = lazy(() => import('./pages/LoginResetPassword'));
const LoginUpdatePassword = lazy(() => import('./pages/LoginUpdatePassword'));

const doMakeUserConfirmPassword = true;

const theme = createTheme(themeStyles);

export default function KcPage(props: { kcContext: KcContext }) {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <KcPageContextualized {...props} />
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

function KcPageContextualized(props: { kcContext: KcContext }) {
  const { kcContext } = props;

  const { i18n } = useI18n({ kcContext });

  const { classes } = useStyles();

  return (
    <Suspense>
      {(() => {
        switch (kcContext.pageId) {
          case 'login.ftl':
            return <Login {...{ kcContext, i18n, classes }} Template={Template} doUseDefaultCss={true} />;
          case 'login-reset-password.ftl':
            return (
              <LoginResetPassword
                {...{ kcContext, i18n, classes }}
                Template={Template}
                doUseDefaultCss={true}
              />
            );
          case 'login-update-password.ftl':
            return (
              <LoginUpdatePassword
                {...{ kcContext, i18n, classes }}
                Template={Template}
                doUseDefaultCss={true}
              />
            );
          default:
            return (
              <DefaultPage
                kcContext={kcContext}
                i18n={i18n}
                classes={classes}
                Template={Template}
                doUseDefaultCss={true}
                UserProfileFormFields={UserProfileFormFields}
                doMakeUserConfirmPassword={doMakeUserConfirmPassword}
              />
            );
        }
      })()}
    </Suspense>
  );
}

const useStyles = tss.create({
  kcBodyClass: {
    fontFamily: 'Roboto'
  },
  kcFormSettingClass: {
    padding: '0px',
    justifyContent: 'end',
    '& .login-pf-settings': {
      alignItems: 'end'
    }
  },
  kcInputClass: {
    height: '40px',
    '& .pf-c-input-group': {
      borderRadius: '4px',
      height: '36px',
      border: '1px solid #cbcbcb'
    }
  },
  kcFormPasswordVisibilityButtonClass: {
    display: 'none'
  },

  kcLabelClass: {
    marginBottom: '8px'
  },

  kcFormGroupClass: {
    display: 'flex',
    'flex-direction': 'column',
    padding: '8px 0px 8px 0px',
    '& .kcFormSettingClass': {
      justifyContent: 'end'
    }
  },

  kcFormCardClass: {
    '& .card-pf': {
      padding: '32px'
    }
  },

  kcCommonLogoIdP: {
    fontSize: '16px',
    width: '30px',
    height: '25px',
    paddingRight: '35px'
  }
} satisfies { [key in ClassKey]?: unknown });
