import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { clsx } from 'keycloakify/tools/clsx';
import type { TemplateProps } from 'keycloakify/login/TemplateProps';
import { getKcClsx } from 'keycloakify/login/lib/kcClsx';
import { useSetClassName } from 'keycloakify/tools/useSetClassName';
import { useInitialize } from 'keycloakify/login/Template.useInitialize';
import type { I18n } from './i18n';
import type { KcContext } from './KcContext';
import { useStyles } from 'tss-react/mui';
import Toolbar from '@mui/material/Toolbar';
import AppBarUI from '@mui/material/AppBar';
import { kcSanitize } from 'keycloakify/lib/kcSanitize';

const headerImage = 'https://app.ampcontrol.io/images/Ampcontrol-horizontal-logo-white.png';
const poweredByAmpLogo = 'https://app.ampcontrol.io/images/poweredByAmpLogo_mobile.png';

export default function Template(props: TemplateProps<KcContext, I18n>) {
  const {
    displayInfo = false,
    displayMessage = true,
    displayRequiredFields = false,
    headerNode,
    socialProvidersNode = null,
    infoNode = null,
    bodyClassName,
    kcContext,
    i18n,
    doUseDefaultCss,
    classes,
    children
  } = props;

  const isSmallScreen = useMediaQuery('(max-width:960px)');

  const { kcClsx } = getKcClsx({ doUseDefaultCss, classes });

  const { msg, msgStr } = i18n;

  const { auth, url, message, isAppInitiatedAction } = kcContext;

  useSetClassName({
    qualifiedName: 'html',
    className: kcClsx('kcHtmlClass')
  });

  useSetClassName({
    qualifiedName: 'body',
    className: bodyClassName ?? kcClsx('kcBodyClass')
  });

  const { css, cx } = useStyles();

  const { isReadyToRender } = useInitialize({ kcContext, doUseDefaultCss });

  if (!isReadyToRender) {
    return null;
  }

  return (
    <>
      <AppBarUI position="sticky" sx={{ zIndex: '1201' }}>
        <Toolbar
          disableGutters
          sx={{
            paddingLeft: '16px',
            paddingRight: '8px',
            background: 'black',
            height: '48px',
            minHeight: '48px',
            '@media (min-width: 600px)': {
              height: '48px',
              minHeight: '48px'
            }
          }}
        >
          <Box
            component="img"
            src={isSmallScreen ? poweredByAmpLogo : headerImage}
            alt="Ampcontrol Logo"
            height="auto"
            width="150px"
            sx={{
              position: 'relative',
              cursor: 'pointer',
              marginRight: '8px',
              height: '38px',
              boxSizing: 'content-box',
              '@media (max-width: 960px)': {
                width: 'auto'
              },
              '& img, & picture': {
                height: '100%'
              }
            }}
          />
        </Toolbar>
      </AppBarUI>
      <div
        className={cx(
          kcClsx('kcLoginClass'),
          css({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            marginTop: '-32px'
          })
        )}
      >
        <div
          className={cx(
            kcClsx('kcFormCardClass'),
            css({
              padding: '32px',
              borderRadius: '4px',
              border: '1px solid #EBEBEB',
              width: '500px'
            })
          )}
        >
          <header>
            {(() => {
              const node = !(auth !== undefined && auth.showUsername && !auth.showResetCredentials) ? (
                <Typography variant="h6" align="center" id="kc-page-title" sx={{ paddingBottom: '24px' }}>
                  {headerNode}
                </Typography>
              ) : (
                <div id="kc-username" className={kcClsx('kcFormGroupClass')}>
                  <label id="kc-attempted-username">{auth.attemptedUsername}</label>
                  <a
                    id="reset-login"
                    href={url.loginRestartFlowUrl}
                    aria-label={msgStr('restartLoginTooltip')}
                  >
                    <div className="kc-login-tooltip">
                      <i className={kcClsx('kcResetFlowIcon')}></i>
                      <span className="kc-tooltip-text">{msg('restartLoginTooltip')}</span>
                    </div>
                  </a>
                </div>
              );

              if (displayRequiredFields) {
                return (
                  <div className={kcClsx('kcContentWrapperClass')}>
                    <div className={clsx(kcClsx('kcLabelWrapperClass'), 'subtitle')}>
                      <span className="subtitle">
                        <span className="required">*</span>
                        {msg('requiredFields')}
                      </span>
                    </div>
                    <div className="col-md-10">{node}</div>
                  </div>
                );
              }

              return node;
            })()}
          </header>
          <div id="kc-content">
            {/* App-initiated actions should not see warning messages about the need to complete the action during login. */}
            {displayInfo && !displayMessage && (
              <div id="kc-info">
                <div id="kc-info-wrapper">{infoNode}</div>
              </div>
            )}
            {displayMessage &&
              message !== undefined &&
              (message.type !== 'warning' || !isAppInitiatedAction) && (
                <Box
                  p={2}
                  mb={3}
                  textAlign="left"
                  sx={{
                    border: 'none',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  className={clsx(
                    `alert-${message.type}`,
                    kcClsx('kcAlertClass'),
                    `pf-m-${message?.type === 'error' ? 'danger' : message.type}`
                  )}
                >
                  <div className="pf-c-alert__icon">
                    {message.type === 'success' && <span className={kcClsx('kcFeedbackSuccessIcon')}></span>}
                    {message.type === 'warning' && <span className={kcClsx('kcFeedbackWarningIcon')}></span>}
                    {message.type === 'error' && <span className={kcClsx('kcFeedbackErrorIcon')}></span>}
                    {message.type === 'info' && <span className={kcClsx('kcFeedbackInfoIcon')}></span>}
                  </div>
                  <Typography
                    sx={{ fontsize: '14px' }}
                    className={kcClsx('kcAlertTitleClass')}
                    dangerouslySetInnerHTML={{ __html: kcSanitize(message.summary) }}
                  />
                </Box>
              )}
            {children}
            {auth !== undefined && auth.showTryAnotherWayLink && (
              <form id="kc-select-try-another-way-form" action={url.loginAction} method="post">
                <div className={kcClsx('kcFormGroupClass')}>
                  <input type="hidden" name="tryAnotherWay" value="on" />
                  <a
                    href="/#"
                    id="try-another-way"
                    onClick={() => {
                      document.forms['kc-select-try-another-way-form' as never].submit();
                      return false;
                    }}
                  >
                    {msg('doTryAnotherWay')}
                  </a>
                </div>
              </form>
            )}
            {socialProvidersNode}
          </div>
        </div>
      </div>
    </>
  );
}
