import { DeprecatedThemeOptions, Theme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import colors from 'common/colors';

const commonTableCellStyles = {
  width: 'calc(100%/7)',
  maxWidth: '300px',
  minWidth: '14em',
  display: 'table-cell',
  margin: '0 auto'
};

const commonContainerStyles = {
  backgroundColor: '#FFFFFF',
  border: `1px solid ${colors.table.darkGrey}`,
  borderRadius: '0 0 3px 3px',
  display: 'flex',
  justifyContent: 'end'
};

export const useTableStyles = makeStyles({
  tableHeader: { whiteSpace: 'nowrap', background: colors.table.lightGrey },
  stickyColumn: {
    borderLeft: `1px solid ${colors.table.darkGrey}`,
    position: 'sticky',
    right: 0,
    zIndex: 2,
    background: colors.table.lightGrey,
    width: '50px'
  },

  activeSortingIcon: {
    color: `${colors.icons.blue} !important`
  },

  tableWithNoData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '32px'
  },

  cellWithBadge: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 12px',
    borderRadius: '5px',
    color: 'black',
    maxWidth: 'fit-content',
    textAlign: 'center'
  },

  alertCountBadge: {
    position: 'absolute',
    top: '-6px',
    right: '-12px',
    color: 'white',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 500,
    background: colors.dark.red
  },

  dropdownTable: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: colors.table.lightGrey
    },
    '& .MuiTableRow-root': {
      backgroundColor: colors.table.lightGrey,
      '&:hover': {
        backgroundColor: colors.table.darkGrey
      }
    }
  },

  dropdownChildrenTable: {
    '& .MuiTableRow-root': {
      backgroundColor: '#FFFFFF',
      '&:hover': {
        backgroundColor: colors.table.lightGrey
      }
    }
  },

  tableExternal: {
    border: 'unset'
  },

  tableTabContainer: {
    ...commonContainerStyles,
    borderBottom: 'unset',
    borderRadius: '3px 3px 0 0',
    '& ~ .MuiTableContainer-root': {
      borderRadius: '0 0 3px 3px'
    }
  },

  multipleTabsContainer: {
    ...commonContainerStyles,
    borderBottom: 'none',
    borderRadius: '3px 3px 0 0'
  },

  tableFooterContainer: {
    ...commonContainerStyles,
    borderLeft: 'unset',
    borderRight: 'unset'
  },

  tablePaginationContainer: {
    ...commonContainerStyles,
    alignItems: 'center'
  },

  tableWithoutPagination: {
    border: `1px solid ${colors.table.darkGrey}`
  },

  tableWithDropdownPaginationContainer: {
    backgroundColor: colors.table.lightGrey
  },

  tableCellStyling: {
    ...commonTableCellStyles,
    textAlign: 'left'
  },

  tableCellWithExpandedRowIcon: {
    ...commonTableCellStyles,
    width: 'auto',
    minWidth: '14px',
    maxWidth: '14px',
    textAlign: 'left'
  },

  tableCellConnectorStyling: {
    ...commonTableCellStyles,
    paddingLeft: '26px',
    textAlign: 'left'
  },

  tableCellWithActiveAlertBadge: {
    ...commonTableCellStyles,
    width: 'auto',
    maxWidth: '80px',
    minWidth: '80px',
    textAlign: 'center'
  },

  tableCellLastCell: {
    ...commonTableCellStyles,
    textAlign: 'center'
  }
});

export const themeStyles = {
  typography: {
    htmlFontSize: 16, // Set htmlFontSize to match MUI v4
    fontSize: 14 // Set the default font size to match MUI v4
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: { backgroundImage: 'unset' },
        elevation1: {
          boxShadow: 'none',
          border: `1px solid ${colors.table.darkGrey}`
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: `1px solid ${colors.table.darkGrey}`,
          borderRadius: '3px 3px 0 0',
          height: '70vh',
          backgroundColor: colors.table.white
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: `${colors.medium.darkGrey}`,
          fontSize: '0.875rem',
          lineHeight: 1.5
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '100%'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            fontSize: '14px !important',
            lineHeight: 1.5
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px !important',
          lineHeight: 1.5
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            '& .MuiTableSortLabel-icon': {
              color: `${colors.medium.lightGrey}`
            }
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        stickyHeader: {
          backgroundColor: colors.table.lightGrey,
        },
        root: {
          borderBottom: `1px solid ${colors.table.darkGrey}`,
          padding: '12px 16px',
          whiteSpace: 'nowrap',
          width: 'max-content',
          '& .MuiLink-root': {
            color: colors.icons.black,
            textDecoration: 'underline',
            textDecorationColor: colors.medium.lightGrey,
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
              padding: '4px 4px',
              margin: '-4px -4px',
              textDecorationColor: colors.medium.lightGrey,
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '60px',
          minHeight: '60px',

          '&:hover': {
            backgroundColor: colors.table.lightGrey
          }
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          color: '#0000008a',
          '&.Mui-selected': {
            color: '#007DFF'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#007DFF'
    },
    secondary: {
      main: 'rgba(0, 0, 0, 0.05)'
    },
    text: {
      primary: '#333333',
      secondary: 'rgba(0, 0, 0, 0.54)'
    },
    background: {
      default: '#FBFBFB'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        "input[type='time']::-webkit-calendar-picker-indicator": {
          background: 'none'
        }
      }
    }
  }
} as DeprecatedThemeOptions;

export const DatePickerGlobalStyles = ({ theme }: { theme: Theme }) => (
  <GlobalStyles
    styles={{
      '.react-datepicker': {
        fontFamily: theme.typography.fontFamily,
        borderRadius: theme.shape.borderRadius,
        padding: '5px',
        marginTop: '-10px',
        marginRight: '20px'
      },
      '.react-datepicker__header': { display: 'none' },
      '.react-datepicker__triangle': { display: 'none' },
      '.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name': {
        color: theme.palette.text.primary
      },
      '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected':
        { backgroundColor: theme.palette.primary.main },
      '.react-datepicker__day:hover, .react-datepicker__time-list-item:hover': {
        backgroundColor: theme.palette.action.hover
      },
      '.react-datepicker__time-list-item': {
        fontFamily: theme.typography.fontFamily,
        fontSize: 14,
        lineHeight: '2.5em',
        height: 'unset !important',
        padding: 'unset !important'
      }
    }}
  />
);

export const useAssetAccessSectionStyles = makeStyles(() => ({
  container: {
    background: '#fafafa',
    padding: '24px',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #ebebeb',
    borderBottom: '.5px solid #ebebeb',
    borderTop: '.5px solid #ebebeb',
    borderRadius: 4,
    marginTop: 24
  },
  subContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' },
  fontStyle: { fontWeight: 'bold', fontSize: '14px' }
}));

export const useTabStyles = makeStyles({
  tabs: { minHeight: 64, height: 64, borderBottom: '1px solid', borderBottomColor: colors.table.darkGrey },
  tab: { minHeight: 64, height: 64 },
  largeTab: { minHeight: 64, height: 64, minWidth: 164, paddingLeft: 32, paddingRight: 32 }
});

export const useModalStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    width: '50%',
    maxWidth: 500,
    maxHeight: '80%',
    overflow: 'scroll',
    padding: 32,

    '@media (max-width: 960px)': {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%'
    }
  }
});

export const useButtonStyles = makeStyles({
  noPaddingButton: {
    padding: 0,
    minWidth: 'unset',
    background: 'unset'
  },
  iconButton: {
    '&:hover': {
      background: 'unset',

      '& svg': {
        color: `${colors.icons.blue} !important`
      }
    }
  },
  redIconButton: {
    '&:hover': {
      background: 'unset',

      '& svg': {
        color: `${colors.icons.red} !important`
      }
    }
  }
});

export const useMapStyles = makeStyles({
  marker: {
    width: '25px',
    height: '25px',
    border: '2px solid white',
    borderRadius: '50%',
    backgroundColor: '#545353',
    cursor: 'pointer'
  },
  popup: {
    translate: '0 -12px',
    '@global': {
      '.mapboxgl-popup-tip': {
        borderTopColor: 'rgba(84, 83, 83, 0.9)'
      },
      '.mapboxgl-popup-content': {
        backgroundColor: 'rgba(84, 83, 83, 0.9)',
        padding: '5px 10px',
        color: 'white',
        fontWeight: 500
      }
    }
  }
});
