const palette = {
  light: {
    darkGreen: '#9ADEBC',
    lightGreen: '#CFF0D2',
    darkBlue: '#CDDEFF',
    lightBlue: '#CEEAFF',
    teal: '#D1F4F2',
    pink: '#FFD9F0',
    orange: '#FFDBA0',
    yellow: '#FDFAD7',
    red: '#FFDBD9',
    violet: '#F1DDFA',
    purple: '#D3CCE1',
    lightGrey: '#EFEDEE',
    darkGrey: '#D1D1D1',
    brown: '#F3DFD7'
  },

  medium: {
    darkGreen: '#74BE99',
    lightGreen: '#A6E7AC',
    darkBlue: '#8C9EFF',
    lightBlue: '#8CC6FB',
    teal: '#8FF1EB',
    pink: '#FFABD8',
    orange: '#FBBB5D',
    yellow: '#FFF6A6',
    red: '#FF8282',
    violet: '#DE9BFC',
    purple: '#B39DDB',
    lightGrey: '#C8C8C8',
    darkGrey: '#929292',
    brown: '#DC9C86'
  },

  dark: {
    darkGreen: '#3DA671',
    lightGreen: '#74CE7C',
    darkBlue: '#304FFE',
    lightBlue: '#2597FF',
    teal: '#57CEC7',
    pink: '#ED65AE',
    orange: '#FF9800',
    yellow: '#FAED78',
    red: '#F61717',
    violet: '#BF4AF4',
    purple: '#5E35B1',
    lightGrey: '#969599',
    darkGrey: '#474747',
    brown: '#A65E45'
  },

  active: {
    light: '#EBF5FF',
    dark: '#007DFF'
  },

  background: {
    lightGrey: '#FBFBFB',
    white: '#FFFFFF'
  },

  table: {
    lightGrey: '#F8F8F8',
    darkGrey: '#EBEBEB',
    white: '#FFFFFF',
    lightBlue: '#EBF5FF',
    lightRed: '#FDECEB'
  },

  text: {
    black: '#000000',
    grey: '#757575',
    blue: '#007DFF',
    red: '#F61717'
  },

  icons: {
    black: '#000000',
    lightGrey: '#C8C8C8',
    darkGrey: '#757575',
    blue: '#007DFF',
    red: '#F61717',
    lightRed: '#EB4646',
    green: '#3DA671'
  }
};

const colors = {
  ...palette,

  connector1: '#BF4AF4',
  connector2: '#FF9800',
  connector3: '#AEC852',
  connector4: '#33E3F2',
  connector5: '#F499D2',
  connector6: '#33DC8B',
  connector7: '#F4777C',
  connector8: '#409DF3',
  connector9: '#9066BD',
  connector10: '#62E9D1',

  group1: '#CDDEFF',
  group2: '#FFD9F0',
  group3: '#9ADEBC',
  group4: '#F3DFD7',
  group5: '#D1F4F2',
  group6: '#D3CCE1',
  group7: '#FFDBA0',
  group8: '#F1DDFA',
  group9: '#CFF0D2',

  alerCharger: '#E5DAEA',
  alerConnector: palette.light.lightBlue,
  alerVehicle: palette.light.teal,
  alerIdTag: palette.light.orange,
  alerOperations: palette.light.yellow,
  alerMaintenance: '#F5DCE5',
  alerSystemError: '#DFDEDC',
  alerSettingsError: palette.light.lightGrey,
  alertHardware: '#EDDCD6',
  alerSoftware: palette.light.darkGreen,

  statusResolved: palette.light.lightGreen,
  statusUnresolved: palette.light.red,
  statusAcknowledged: palette.light.yellow,
  statusTriggered: palette.light.red,

  insideDepot: palette.light.lightBlue,
  outsideDepot: palette.light.yellow,
  delete: palette.dark.red,

  source: {
    charger: palette.light.lightGreen,
    ampCMS: palette.light.lightBlue,
    ampCMS_queued: palette.light.red,
    ampCMS_created: palette.light.yellow,
    ampEdge: palette.light.teal,
    ui: palette.light.pink,
    external: palette.light.lightGrey,
    api: palette.light.orange
  }
};

export default colors;
