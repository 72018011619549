
import { i18nBuilder } from "keycloakify/login";
import type { ThemeName } from "../kc.gen";

const { useI18n, ofTypeI18n } = i18nBuilder
    .withThemeName<ThemeName>()
    .withCustomTranslations({
      en: {
    "loginAccountTitle": "Sign In",
    "identity-provider-login-label": "Or continue with",
    "invalidUsernameMessage": "No account found with that email. Please check and try again. If the issue continues, contact your admin.",
    "invalidEmailMessage": "No account found with that email. Please check and try again. If the issue continues, contact your admin.",
    "invalidUsernameOrEmailMessage": "No account found with that email. Please check and try again. If the issue continues, contact your admin.",
    "invalidUserMessage": "The email or password is incorrect. Please check and try again, or click 'Forgot password'",
    "invalidPasswordMessage": "The password is incorrect. Please check and try again, or click 'Forgot password'",
    "emailInstruction": "Enter your email address and we will send you instructions on how to create a new password.",
    "passwordNew": "Create new password",
    "passwordConfirm": "Confirm new password",
    "updatePasswordTitle": "Reset Password",
    "emailForgotTitle": "Reset Password"
    }
})
.build();

type I18n = typeof ofTypeI18n;

export { useI18n, type I18n };
